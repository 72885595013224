<template>
  <div>
    <BannerComponent name="Contacto" :image= "require('../assets/img/street2.jpg')" />
   <div class="grid md:grid-cols-3 ml-50 items-center md:mt-0 mx-10 mt-5">
    <div class="hidden md:hidden lg:block pl-5 col-span-1 content-center items-center h-full w-auto bg-gray-200">
      <img class="lg:h-80 ml-6" src="../assets/img/sello_blanco_grande.png" alt="Sello">
    </div>
    <div class="grid pb-10 items-center md:items-left md:ml-10 col-span-2 mt-10 h-auto mb-10 justify-left">
      <div class="w-full max-w-[550px]">
        <h1 class="mb-10 text-center text-5xl text-cyan-800 md:text-left">¡Esperamos tu mensaje!</h1>
        
        <form action="https://formbold.com/s/FORM_ID" method="POST" @submit.prevent="sendContact">
          <div class="mb-5">
            <label
              for="name"
              class="mb-3 block text-base font-bold text-cyan-900"
            >
            Nombre y Apellido
            </label>
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Nombre y Apellido"
              class="w-full rounded-md border border-gray-400 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
            v-model="contact.name" required />
          </div>
          <div class="mb-5">
            <label
              for="email"
              class="mb-3 block text-base font-bold text-cyan-900"
            >
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="ejemplo@domain.com"
              class="w-full rounded-md border border-gray-400 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
            v-model="contact.email" required />
          </div>
          <div class="mb-5">
            <label
              for="subject"
              class="mb-3 block text-base font-bold text-cyan-900"
            >
              Asunto
            </label>
            <input
              type="text"
              name="subject"
              id="subject"
              placeholder="Asunto"
              class="w-full rounded-md border border-gray-400 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
            v-model="contact.subject" required />
          </div>
          <div class="mb-5">
            <label
              for="message"
              class="mb-3 block text-base font-bold text-cyan-900"
            >
              Mensaje
            </label>
            <textarea
              rows="4"
              name="message"
              id="message"
              placeholder="Escriba su mensaje"
              class="w-full resize-none rounded-md border border-gray-400 bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
            v-model="contact.message" required ></textarea>
          </div>
          <div>
            <button
              class="hover:shadow-form rounded-md bg-cyan-900  hover:bg-cyan-800 py-3 px-8 text-base font-semibold text-white outline-none"
            v-bind:class="{'is-loading':sending}" type="submit">
            Enviar
            </button>
          </div>
        </form>
      </div>
    </div>
   </div>
  </div>
</template>

<script>
// @ is an alias to /src

import BannerComponent from '@/components/BannerComponent.vue'
import axios from 'axios'
import { baseURL } from '../config.js'
export default {
  name: 'ContactView',
  components: {
    BannerComponent,
  },
  data() {
      return {
          contact: {
              name: '',
              email: '',
              subject: '',
              message: '',
              
          },
        sending: false,  
      }
    },
    methods:{
        sendContact: async function(){
           this.sending = true 
            /**const data = {
                'name': this.contact.name,
                'email': this.contact.email,
                'subject': this.contact.subject,
                'message': this.contact.message,
            }**/

            await axios
                .post( baseURL + "contact/", this.contact)
                .then((response) => {
                  if (response.status == 200){
                    alert("Mensaje enviado! Nos comunicaremos pronto con usted")
                    this.contact.name = ''
                    this.contact.email = ''
                    this.contact.subject = ''        
                    this.contact.message = ''
                    this.sending = false
                  }
                })
                .catch((error) => {
                    alert("Ocurrió un error, por favor intente más tarde." + error)
                    this.sending = false
                });
        }
    }
}
</script>