import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CategoryDetailView from '../views/CategoryDetailView.vue'
import ProgramView from '../views/ProgramView.vue'
import ProgramDetailView from '../views/ProgramDetailView.vue'
import DestinationDetailView from '../views/DestinationDetailView.vue'
import DestinationView from '../views/DestinationView.vue'
import AboutView from '../views/AboutView.vue'
import ContactView from '../views/ContactView.vue'
import SearchView from '../views/SearchView.vue'



const routes = [
  {
    path: '/',
    name: 'Eunoia Travel - Inicio',
    component: HomeView
  },
  {
    path: '/categoria/:category_slug',  
    name: 'Eunoia Travel - Categoria',
    component: CategoryDetailView
  },
  {
    path: '/programas',
    name: 'Eunoia Travel - Programas',
    component: ProgramView
  },
  {
    path:'/programa/:program_slug',
    name: 'Eunoia Travel - Programa',
    component: ProgramDetailView
  },
  {
    path:'/destinos',
    name: 'Eunoia Travel - Destinos',
    component: DestinationView
  },
  {
    path:'/destino/:destination_slug',
    name: 'Eunoia Travel - Destino',
    component: DestinationDetailView
  },
  
  {
    path: '/nosotros',
    name: 'Eunoia Travel - Nosotros',
    component: AboutView
  },
  {
    path: '/contacto',
    name: 'Eunoia Travel - Contacto',
    component: ContactView
  },
  {
    path: '/resultados/:search',
    name: 'Eunoia Travel - Resultados',
    component: SearchView
  }



]

const router = createRouter({
  history: createWebHistory('/'),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  document.title = to.name;
  next();
});

export default router
