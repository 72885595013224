<template>
  <NavBarComponent/>
  <router-view/>
  <FooterComponent/>
</template>



<script>
// @ is an alias to /src
import NavBarComponent from '@/components/NavBarComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'



export default {
  name: 'AppView',
  components: {
    NavBarComponent,
    FooterComponent,
    
    
  }
}
</script>
