<template>
    <section class="mb-10 text-gray-700">
  <div class="text-center md:max-w-xl  lg:max-w-3xl mx-10 md:mx-auto">
    <div>
          <p class="inline-block px-3 mb-4 text-2xs font-semibold uppercase tracking-wider text-white rounded-full bg-cyan-700">
            #feedback
          </p>
        </div>
    <h1 class="text-center  text-gray-900 text-4xl mb-5">{{title}}</h1>
    <p class="text-center font-semibold text-cyan-700 text-xl mb-10 md:mb-12 md:pb-0">
      Algunos de nuestros alumnos nos comparten sus experiencias de viaje junto al equipo de Eunoia Travel.
    </p>
    
  </div>

  <div class="grid md:grid-cols-3 gap-6 lg:gap-12 text-center mx-20">

    <!--TESTIMONIO 1-->
    
    <div class="md:mb-10 mb-2">
      <div class="flex ml-16 lg:ml-32 border border-gray-200 bg-white px-2 w-20 h-20 rounded-full bg-red justify-center mb-6">
       <svg class="fill-cyan-800" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
       </svg>
      </div>
      <h5 class="text-2xl font-semibold mb-4">Tomas M.</h5>
      <h6 class="font-semibold text-cyan-700 mb-4">Estudiante</h6>
      <p class="md:mb-4 font-medium text-center italic md:mx-6">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="quote-left"
          class="w-6 pr-2 inline-block"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            fill="currentColor"
            d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"
          ></path>
        </svg>
       Viajé junto a Eunoia Travel en  una salida grupal a Londres por 3 semanas y sin dudas fueron de las mejores de mi vida. No solo perfeccione mi inglés, sino que me llené de recuerdos inolvidables, conocí personas y lugares increíbles. Contamos con acompañamiento constante y las excursiones fueron excelentes. El sentimiento es único y quienes vivimos esta experiencia lo tenemos siempre presente. 
      </p>
    </div>

    <!--TESTIMONIO 2-->

    <div class="md:mb-10 mb-2">
      <div class="flex ml-16 lg:ml-32 border border-gray-200 bg-white px-2 w-20 h-20 rounded-full bg-red justify-center mb-6">
       <svg class="fill-cyan-800" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
       </svg>
      </div>
      <h5 class="text-2xl font-semibold mb-4">Valentina B.</h5>
      <h6 class="font-semibold text-cyan-700 mb-4">Estudiante</h6>
      <p class="mb-4 font-medium text-center italic md:mx-6">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="quote-left"
          class="w-6 pr-2 inline-block"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            fill="currentColor"
            d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"
          ></path>
          </svg>
          Participé en un viaje grupal de verano en 2019 y lo disfruté tanto que sentí que necesitaba volver, pero esta vez quería a hacer la experiencia de inmersión como estudiante individual, y con la ayuda de Eunoia Travel organizamos mi regreso durante la pandemia. cuando finalmente se abrieron las fronteras, pude volver a estudiar inglés en Londres durante 6 meses y fue un sueño hecho realidad.
      </p>
    </div>

    <!--TESTIMONIO 3-->

    <div class="mb-12 md:mb-0">
      <div class="flex ml-16 lg:ml-32 border border-gray-200 bg-white px-2 w-20 h-20 rounded-full bg-red justify-center mb-6">
       <svg class="fill-cyan-800" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
       </svg>
      </div>
      <h5 class="text-2xl font-semibold mb-4">Lucas A.</h5>
      <h6 class="font-semibold u text-cyan-700 mb-4">Estudiante</h6>
      <p class="mb-4 text-center font-medium italic md:mx-6">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="quote-left"
          class="w-6 pr-2 inline-block"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            fill="currentColor"
            d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"
          ></path>
          </svg>
          Mientras estaba preparándome con la profesora Karina para rendir el examen de Cambridge FCE, me interesó la idea de hacer un viaje de inmersión lingüística y cultural. Realicé el curso en el año 2015 en Londres durante 3 semanas y me hospedé en una casa de familia. 
          Esta experiencia sin duda me dió no sólo la confianza suficiente para aprobar el FCE, sino también para desempeñarme profesionalmente como docente de inglés.
      </p>
    </div>
  </div>
</section>
</template>

<script>

export default {
  name: 'TestimonialsComponent',
  props: {
     name:String,
     title:String,
       
  }
  
}

</script>