<template>
  <div>
    <BannerComponent name="Nosotros" :image= "'https://mdbcdn.b-cdn.net/img/new/slides/146.webp'" />
    <section>
      <div id="about us" >
          <StaffComponent title="Nuestro Equipo"/>
      </div>
    </section>
  
    
  </div>
</template>

<script>
// @ is an alias to /src
import BannerComponent from '@/components/BannerComponent.vue'
import StaffComponent from '../components/StaffComponent.vue'

export default {
  name: 'AboutView',
   components: {
    BannerComponent,
    StaffComponent,
   }
}
</script>
