<template>
    <div class="bg-gradient-to-r from-gray-800 to-cyan-700">
  <div class="mx-10 max-w-7xl py-12 px-4  lg:flex lg:items-center lg:justify-between lg:py-16 lg:px-8">
    <h2 class="text-3xl font-bold tracking-tight text-gray-900 ">
      <span class=" text-center md:text-left block text-3xl md:text-4xl mb-2 uppercase text-white">Estas listo para la aventura?</span>
      <span class="text-center md:text-left block text-2xl md:text-3xl text-cyan-600">Recorre lugares increibles, conoce otras culturas y gente de todo el mundo.</span>
    </h2>
    <div class="justify-center md:ml-5 mt-8 flex lg:mt-0 lg:flex-shrink-0">
      <div class="inline-flex justify-center rounded-md shadow">
        <a href="/contacto" class="inline-flex items-center uppercase justify-center rounded-md border border-transparent bg-gray-800 hover:bg-gray-600 text-center px-5 py-3 text-base font-medium text-white">más información</a>
      </div>
    </div>
  </div>
</div>

</template>

<script>

export default {
  name: 'MiniBannerComponent',
  props: {
     name:String,
     image:String
        
  }  
}

</script>