<template>
    
    
    <div class="flex md:px-0 md:items-center ">
    
    
        <form class=" block justify-center px-auto" action="https://formbold.com/s/FORM_ID" method="POST" @submit.prevent="sendContact">
        <div class="mb-5">
            <label
            for="name"
            class="mb-3 block text-base font-medium text-gray-700"
            >
            Nombre y Apellido
            </label>
            <input
            type="text"
            name="name"
            id="name"
            placeholder="Nombre y Apellido"
            class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
            v-model="contact.name" required  />
        </div>
        <div class="mb-5">
            <label
            for="email"
            class="mb-3 block text-base font-medium  text-gray-700"
            >
            Email
            </label>
            <input
            type="email"
            name="email"
            id="email"
            placeholder="ejemplo@domain.com"
            class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
            v-model="contact.email" required />
        </div>
        <div class="mb-5">
            <label
            for="subject"
            class="mb-3 block text-base font-medium  text-gray-700"
            >
            Asunto
            </label>
            <input
            type="text"
            name="subject"
            id="subject"
            placeholder="Asunto"
            class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
            v-model="contact.subject" required />
        </div>
        <div class="mb-5">
            <label
            for="message"
            class="mb-3 block text-base font-medium  text-gray-700"
            >
            Mensaje
            </label>
            <textarea
            rows="4"
            name="message"
            id="message"
            placeholder="Escriba su mensaje"
            class="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
            v-model="contact.message" required ></textarea>
        </div>
        <div>
            <button
            class="hover:shadow-form rounded-md bg-gray-800 uppercase hover:bg-gray-600 py-3 px-8 text-base font-semibold text-white outline-none"
            v-bind:class="{'is-loading':sending}" type="submit" >
            Enviar
            </button>
        </div>
        </form>
    
    </div>
        
  
</template>
<script>
import axios from 'axios'
import { baseURL } from '../config.js'

export default {
  name: 'FormComponent',
  components: {
    
  },
 data() {
      return {
          contact: {
              name: '',
              email: '',
              subject: '',
              message: '',
              
          },
        sending: false,  
      }
    },
    methods:{
        sendContact: async function(){
           this.sending = true 
            /**const data = {
                'name': this.contact.name,
                'email': this.contact.email,
                'subject': this.contact.subject,
                'message': this.contact.message,
            }**/

            await axios
                .post( baseURL + "contact/", this.contact)
                .then((response) => {
                  if (response.status == 200){
                    alert("Mensaje enviado! Nos comunicaremos pronto con usted")
                    this.contact.name = ''
                    this.contact.email = ''
                    this.contact.subject = ''        
                    this.contact.message = ''
                    this.sending = false
                  }
                })
                .catch((error) => {
                    alert("Ocurrió un error, por favor intente más tarde." + error)
                    this.sending = false
                });
        }
    }

}
</script>