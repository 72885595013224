<template>
  <header>
    <nav class="bg-gradient-to-r from-gray-900 to-cyan-800 justify-center">
    <div class="mx-auto max-w-7xl pt-3 px-2 sm:px-6 md:px-6 lg:px-8 h-21">
      <div class="relative flex h-28 items-center justify-end ">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
         <div class="dropdown">
          <button type="button" class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
            <span class="sr-only">Open main menu</span>


            <svg v-if="showMobileMenu" id="burguer icon" class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" @click="showMobileMenu = !showMobileMenu">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
            <svg v-else id="close" class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" @click="showMobileMenu = !showMobileMenu">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
            
          </button>
         </div>
        </div>
        <div class="flex flex-1 items-center justify-end sm:items-stretch sm:justify-start">
          <div id= "logo" class="flex items-center pb-3">
            <router-link to="/"><img class="hidden md:hidden lg:block h-36 w-auto " src="../assets/img/eunoia_blanco4.png" loading="auto" alt="Letras"></router-link>
            <router-link to="/"><img class="lg:hidden md:hidden mr-6 h-24 w-auto" src="../assets/img/sello_blanco1.png" alt="Sello"></router-link>
          </div>
          <div class="hidden sm:block  justify-right mt-10  ">
            <div class="absolute right-0  mt-3 origin-top-right space-x-5 ">
              <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
            
            <div class="dropdown inline-block ">
              <router-link to="/programas" @mouseover="programs_menu=true" @mouseleave="programs_menu=false" class="block items-center bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-semibold uppercase whitespace-nowrap" aria-current="page">programas</router-link>
                <div v-show="programs_menu" @mouseover="programs_menu=true" @mouseleave="programs_menu=false">
                        <div class="absolute divide-y divide-gray-100 rounded pt-3 pl-2 pr-2 duration-150 min-w-max bg-white z-50 pb-3  ">
                          <div v-for="program,key in programs.slice(0,3)" :key="key">
                                <router-link v-bind:to="program.get_absolute_url">
                                    <a class="block hover:bg-slate-200 px-2 py-2  text-base uppercase hover:text-gray-900 bold text-gray-700 w-full">
                                      {{program.name}}
                                    </a>
                                </router-link>
                          </div>
                        </div> 
                    </div>
            </div>
           
              <router-link to="/destinos" class=" text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-semibold uppercase">Destinos</router-link>

              <router-link to="/nosotros" class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-semibold uppercase">Nosotros</router-link>

              <router-link to="/contacto" class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-semibold uppercase">Contacto</router-link>
              
              <div class="justify-end items-end inline-block"><SearchComponent /></div>

           </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile menu, show/hide based on menu state. -->
    <div class="sm:hidden" id="mobile-menu">
      <div v-bind:class="{'hidden': showMobileMenu}" class="space-y-1 px-2 pt-2 pb-3">
        <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
        <router-link to="/programas" class="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium" aria-current="page">Programas</router-link>

        <router-link to="/destinos" class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Destinos</router-link>

        <router-link to="/nosotros" class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Nosotros</router-link>

        <router-link to="/contacto" class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Contacto</router-link>
      </div>
    </div>
</nav>

  </header>
</template>

<script>
//import axios from 'axios'
import axios from 'axios'
import { baseURL } from '../config.js'

import SearchComponent from '@/components/SearchComponent.vue'



export default {
  name: 'NavBarComponent',
  components: {
    SearchComponent
  },
  props: {
    msg: String
  },
  data(){
   return {
      programs_menu: false,
      programs: [],
      showMobileMenu: true,
    };
  },
  mounted() {
    this.getPrograms()
  },

  methods:{
    getPrograms: async function(){
      await axios.get(baseURL + 'program/')
                  .then((response) => {
                    this.programs = response.data.filter(function(p){
                      return p.show_on_menu == true
                    })
                  })
                  .catch( error => {
                    console.log(error)
                  })
    },
    closeMenu: async function(){
      let close = document.getElementById("close");
       close.classList.add('hidden');
    }
  }
}
 



</script>

