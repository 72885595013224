<template> 
    <div id="Category Datail">
       
        <BannerComponent :name="category.name" :image= "require('../assets/img/street2.jpg')" />
         <div class="flex flex-col md:mx-20 mx-5">
         <div class="grid md:grid-cols-2 items-center mx-10 mt-10">
          <div class="grid col-span-2 content-center">
            <h1 class="mb-6 mt-10 md:text-left text-center text-5xl text-black">{{category.name}}</h1>
            <h1 class="mb-6 text-center md:text-left text-cyan-700 ">¿En qué consiste?</h1>
            <p class="mb-6 leading-loose  text-gray-700 text-justify text-lg">{{category.description}}</p>
          </div>
         </div> 
        <h1 class=" text-cyan-700 uppercase mb-4 mt-7 text-center"> Programas Relacionados </h1>
        <div class="flex justify-center">
          <div class="grid items-center md:grid-cols-3 gap-x-6 gap-y-10 md:w-5/6 w-auto h-auto aspect-video mt-2 mb-40 ">
            <div v-for="program, key in programs.slice(0,3)" :key="key">
                <router-link v-bind:to="program.get_absolute_url">
                  <ProgramsCardComponent :program="program" />
                </router-link>
            </div>
          </div>
        </div>
       </div>
    </div>
    
</template>

<script>
import axios from 'axios'
import { baseURL } from '../config.js'
import BannerComponent from '@/components/BannerComponent.vue'
import ProgramsCardComponent from '@/components/ProgramsCardComponent'


export default {
  name: 'CategoryDetailView',
  props: {
    name: String,
  },
  components: {
    BannerComponent,
    ProgramsCardComponent
  },
data() {
    return {
     category: [],
     programs: []
    };
  },
   created(){
    this.getCategory()
    this.getRelatedPrograms()
  },
  watch: {
    '$route' (to) {
      if(to.name == 'categoria')
        this.getCategory()
        this.getRelatedPrograms()
    }
  },
methods:{
    getCategory: async function(){
      await axios.get(baseURL + `category/${this.$route.params.category_slug}/`)
                  .then((response) => {
                    this.category = response.data
                  })
                  .catch( error => {
                    console.log(error)
                  })
    },
    getRelatedPrograms: async function(){
      await axios.get(baseURL + `programs/${this.$route.params.category_slug}/`)
                  .then((response) => {
                    this.programs = response.data
                  })
                  .catch( error => {
                    console.log(error)
                  })
    }
  }
}
</script>
