<template>
<div class="mb-7" id= "destination card">
    <a v-bind:to="destination.get_absolute_url">
      <div class="cursor-pointer brightness-75 hover:brightness-100 rounded-lg shadow-lg shadow-slate-600 bg-gradient-to-t from-gray-900 to-cyan-700 justify-center ">
          <a href="#!">
          <img class="rounded-t-xl w-full" :src="destination.get_picture" alt=""/>
          </a>
          <div class="p-4">
            <h5 class="mb-2 text-gray-200 text-center uppercase text-xl font-semibold">{{destination.name}}</h5>
            <p class= "text-gray-200 text-5xs text-justify ">{{destination.short_description}}</p>
          </div>
      </div>
    </a>
</div>         
</template>

<script>


export default {
    name: 'DestinationsComponent',
    props: {
        destination: Object
    },
     
}
</script>
