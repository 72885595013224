<template>
  <div>
     <BannerComponent name='Programas' :image= "require('../assets/img/street2.jpg')"/>
      <h1 class="text-black text-4xl text-center mt-10 mb-5">Nuestros Programas</h1>
      <p class="font-semibold text-cyan-700 text-xl md:mx-36 text-center leading-loose">Tenemos cursos de inglés generales y especializados, de corta y larga duración,</p>
      <p class="font-semibold text-cyan-700 text-xl md:mx-36 text-center leading-loose">para todas las edades y niveles de conocimiento del idioma.</p>
      <div class="flex justify-center">
         <div class="grid md:grid-cols-3 gap-x-6 gap-y-10 h-auto w-4/5 aspect-video mt-10 mb-40 ">
          <div v-for="program, key in programs" :key="key">
            <router-link v-bind:to="program.get_absolute_url">
              <ProgramsCardComponent :program="program" />
            </router-link>
          </div>
         </div>
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import { baseURL } from '../config.js'
import BannerComponent from '@/components/BannerComponent.vue'
import ProgramsCardComponent from '@/components/ProgramsCardComponent.vue'

export default {
  name: 'ProgramView',
  props: {
    name: String,
  },
  components: {
    BannerComponent,
    ProgramsCardComponent
  },
 data() {
    return {
     programs: [],
    };
  },
   mounted(){
    this.getPrograms();
  },
  methods:{
    getPrograms: async function(){
      await axios.get(baseURL + 'program/')
                  .then((response) => {
                    this.programs = response.data
                  })
                  .catch( error => {
                    console.log(error)
                  })
    }
  }
}
</script>
