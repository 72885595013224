<template> 
<div>
      <BannerComponent name='Destinos' :image= "require('../assets/img/street2.jpg')" />
       <div class="flex flex-col mx-10">
          <div class="grid md:grid-cols-3 items-center md:mt-0 mt-5">
            <div class="col-span-2 ml-0 mr-6">
              <h1 class="text-black text-center lg:text-left mb-7 mt-10 text-5xl">{{destination.name}}</h1>
              <h1 class="text-black text-center lg:text-left mb-6 text-4xl">¿Cómo es la ciudad?</h1>
              <p class="text-cyan-700 font-semibold leading-loose text-justify text-lg mb-4">{{destination.description}}</p>
            </div>
            <div class="col-span-2 lg:col-span-1">
              <div class="w-full h-full lg:ml-10 md:w-auto items-center content-center lg:mt-6 md:ml-3 mb-10 ">
                <img alt="city image" class=" lg:w-full md:w-full lg:mt-10 w-full h-full justify-center rounded-lg" :src="destination.get_picture">
              </div>
            </div>
          </div>
         
          <h1 class="text-black mt-10 mb-2 text-center md:text-left text-4xl">Galeria</h1>
          <div class="flex justify-center">
            <div class="grid lg:ml-72 ml-20 items-center mt-2 mb-10  ">
            <SimpleGalleryComponent :pictures= destination.pictures />
            </div>
          </div>
          
        
          
          <h1 class=" text-cyan-700 uppercase text-center lg:mb-3 mb-6 lg:mt-7"> Programas Relacionados </h1>
          <div class="flex justify-center">
            <div class="grid items-center md:grid-cols-3 gap-x-6 gap-y-10 md:w-5/6 w-auto  aspect-video mt-2 mb-40 ">
              <div v-for="program, key in programs.slice(0,3)" :key="key">
                  <router-link v-bind:to="program.get_absolute_url">
                    <ProgramsCardComponent :program="program" />
                  </router-link>
              </div>
            </div>
          </div>
        </div>
         
        
</div>
</template>

<script>
import axios from 'axios'
import { baseURL } from '../config.js'
import BannerComponent from '@/components/BannerComponent.vue'
import SimpleGalleryComponent from '@/components/SimpleGalleryComponent.vue'
import ProgramsCardComponent from '@/components/ProgramsCardComponent'


export default {
  name: 'DestinationDetailView',
  props: {
    name: String,
  },
  components: {
    BannerComponent,
    SimpleGalleryComponent,
    ProgramsCardComponent
  },
data() {
    return {
     destination: {},
     programs: []
    };
  },
   created(){
    this.getDestination()
    this.getRelatedPrograms()
  },
  watch: {
    '$route' (to) {
      if(to.name == 'destino')
        this.getDestination()
        this.getRelatedPrograms()
    }
  },
methods:{
    getDestination: async function(){
      await axios.get(baseURL + `destination/${this.$route.params.destination_slug}/`)
                  .then((response) => {
                    this.destination = response.data
                  })
                  .catch( error => {
                    console.log(error)
                  })
    },
    getRelatedPrograms: async function(){
      await axios.get(baseURL + `programs/destination/${this.$route.params.destination_slug}/`)
                  .then((response) => {
                    this.programs = response.data
                  })
                  .catch( error => {
                    console.log(error)
                  })
    },
  }
}
</script>
