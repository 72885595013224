<template>
   <div class="bg-gray-100 py-10 md:max-w-full px-20 border ">
    
    <div class="flex flex-col lg:flex-row">
        
        <div class="mt-5 mb-6 lg:mb-0 lg:w-1/2 lg:pr-5">
            <span><img class="ml-14 md:ml-28 lg:pl-28 mb-3 h-24 w-auto" src="../assets/img/sello_negro1.png" alt="Sello"></span>
            <h1 class="text-center mb-5 text-3xl font-bold tracking-tight text-gray-900 ">
                ¿Por qué elegir <br class="hidden md:block" />
            <p class=" text-cyan-700 mt-3 text-5xl">Eunoia Travel?</p>
            </h1>
            <p class="text-center font-bold">"Una experiencia que recordarás toda la vida"</p>
         
        </div>
            <div class="lg:w-1/2 w-full text-center">
                <p class="mt-3 text-xl md:mx-10 mb-5 text-justify leading-loose text-gray-700">
                  Nuestro objetivo es acompañar a nuestros alumnos y a sus familias desde el inicio hasta el final de su viaje de estudio.
                </p>
                <p class="text-xl tracking-tight font-semibold md:mx-10 text-justify leading-loose text-gray-700">
                  Asesoramos en cada detalle y aplicamos toda nuestra experiencia para que los estudiantes obtengan el máximo beneficio de la inmersión cultural.
                </p>
                <p class="mt-5 tracking-tight  text-xl md:mx-10 text-justify leading-loose text-gray-700">
                  Diseñamos nuestros programas a medida, enfocados en la calidad, variedad y oportunidades de aprendizaje e intercambio.  
                </p>
                <router-link to="/programas"><button type="button" class="md:ml-44 mt-9  md:block px-10 py-3 bg-gray-800 text-white leading-snug uppercase rounded shadow-md hover:bg-gray-600 hover:shadow-lg focus:bg-gray-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-600 active:shadow-lg font-semibold transition duration-150 ease-in-out">Mas información</button></router-link>
             
           
        </div>
    </div>
   </div>
</template>

<script>
export default {
  name: 'AboutComponent',
  props: {
     name:String,
     image:String
        
  }  
}
</script>
