<template>
  <div id="Destinations card component">
    <div class=" shadow-xl bg-gradient-to-t from-gray-900 to-cyan-700 rounded-t-lg md:hover:scale-110">
      <a>
        <img class="rounded-t-lg" :src="destination.get_picture" alt="">
      </a>
      <div class="p-6">
          <h5 class= "text-gray-200 uppercase text-xl font-semibold text-center mb-2">{{destination.name}}</h5>
          <p class=" text-gray-200 text-center text-base mb-4">{{destination.short_description}}</p>
          <div class="flex items-center justify-center">
            <button type="button" class=" inline-block ring-1 px-6 py-2.5 text-white bg-cyan-700 hover:bg-cyan-600 font-medium text-xs leading-tight uppercase rounded shadow-md transition duration-150 ease-in-out">más información</button>
          </div>
      </div>
    </div>
  </div>
</template>

<script>



export default {
  name: 'DestinationCardComponent',
  props: {
     destination: Object 
  },
}
</script>
