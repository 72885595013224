<template>
    <div class="text-center px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10">
      <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <div>
          <p class="inline-block  px-3 mb-4 text-2xs font-semibold uppercase tracking-wider text-white rounded-full bg-cyan-700">
            #learn
          </p>
        </div>
        <h1 class="text-center text-4xl mb-5  text-black md:mx-auto">
          Cursos diseñados
          <p class="mt-2 text-center">a tu medida</p>
        </h1>
        <div class="flex flex-col lg:flex-row mt-5 md:mx-2 mx-10 md:gap-16">
          <div class="mb-2 w-full">
            <p class="text-cyan-800 text-center font-semibold md:text-xl">
            Tenemos cursos de inglés general y especializado, de corta y larga duración, para todas las edades y niveles de conocimiento del idioma. 
            </p>
          </div>
          
        </div>
      </div>

      <div class="grid gap-4 row-gap-5 sm:grid-cols-2 lg:grid-cols-4 ">
        <!--BOX 1-->
        <div class="flex flex-col justify-between p-5 border rounded shadow-sm hover:bg-gray-200 bg-gray-100">
          <div>
            <div class="flex md:ml-28 max-md:ml-32 ml-32 items-center justify-center w-8 h-10 mb-4 rounded-full">
             <svg style="fill:rgb(22,78,99)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M408 120c0 54.6-73.1 151.9-105.2 192c-7.7 9.6-22 9.6-29.6 0C241.1 271.9 168 174.6 168 120C168 53.7 221.7 0 288 0s120 53.7 120 120zm8 80.4c3.5-6.9 6.7-13.8 9.6-20.6c.5-1.2 1-2.5 1.5-3.7l116-46.4C558.9 123.4 576 135 576 152V422.8c0 9.8-6 18.6-15.1 22.3L416 503V200.4zM137.6 138.3c2.4 14.1 7.2 28.3 12.8 41.5c2.9 6.8 6.1 13.7 9.6 20.6V451.8L32.9 502.7C17.1 509 0 497.4 0 480.4V209.6c0-9.8 6-18.6 15.1-22.3l122.6-49zM327.8 332c13.9-17.4 35.7-45.7 56.2-77V504.3L192 449.4V255c20.5 31.3 42.3 59.6 56.2 77c20.5 25.6 59.1 25.6 79.6 0zM288 152c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40s17.9 40 40 40z"/></svg>
            </div>
            <h6 class="mb-2 font-bold leading-5 uppercase text-center text-cyan-900">RESPONSABILIDAD</h6>
            <p class="mb-3 text-base text-gray-900 text-center">
             Aplicamos toda nuestra experiencia al asesorar a nuestros estudiantes para que disfruten al máximo en un marco confiable y seguro.  
            </p>
          </div>
        </div>

        <!--BOX 2-->
        <div class="flex flex-col justify-between p-5 border rounded shadow-sm  hover:bg-gray-200 bg-gray-100">
          <div>
            <div class="flex md:ml-28 ml-32 items-center justify-center w-8 h-10 mb-4 rounded-full">
              <svg style="fill:rgb(22,78,99)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M57.7 193l9.4 16.4c8.3 14.5 21.9 25.2 38 29.8L163 255.7c17.2 4.9 29 20.6 29 38.5v39.9c0 11 6.2 21 16 25.9s16 14.9 16 25.9v39c0 15.6 14.9 26.9 29.9 22.6c16.1-4.6 28.6-17.5 32.7-33.8l2.8-11.2c4.2-16.9 15.2-31.4 30.3-40l8.1-4.6c15-8.5 24.2-24.5 24.2-41.7v-8.3c0-12.7-5.1-24.9-14.1-33.9l-3.9-3.9c-9-9-21.2-14.1-33.9-14.1H257c-11.1 0-22.1-2.9-31.8-8.4l-34.5-19.7c-4.3-2.5-7.6-6.5-9.2-11.2c-3.2-9.6 1.1-20 10.2-24.5l5.9-3c6.6-3.3 14.3-3.9 21.3-1.5l23.2 7.7c8.2 2.7 17.2-.4 21.9-7.5c4.7-7 4.2-16.3-1.2-22.8l-13.6-16.3c-10-12-9.9-29.5 .3-41.3l15.7-18.3c8.8-10.3 10.2-25 3.5-36.7l-2.4-4.2c-3.5-.2-6.9-.3-10.4-.3C163.1 48 84.4 108.9 57.7 193zM464 256c0-36.8-9.6-71.4-26.4-101.5L412 164.8c-15.7 6.3-23.8 23.8-18.5 39.8l16.9 50.7c3.5 10.4 12 18.3 22.6 20.9l29.1 7.3c1.2-9 1.8-18.2 1.8-27.5zm48 0c0 141.4-114.6 256-256 256S0 397.4 0 256S114.6 0 256 0S512 114.6 512 256z"/></svg>
            </div>
            <h6 class="mb-2 font-bold  text-cyan-900 leading-5 uppercase text-center">FLEXIBILIDAD</h6>
            <p class="mb-3 text-base text-gray-900 text-center">
              Los cursos y niveles para mayores de 16 años comienzan cada semana durante todo el año, puedes elegir el destino que más te guste y el tiempo que desees.
            </p>
          </div>
        </div>

        <!--BOX 3-->
        <div class="flex flex-col justify-between p-5 border rounded shadow-sm  hover:bg-gray-200 bg-gray-100">
          <div>
            <div class="flex md:ml-28 ml-32 items-center justify-center w-8 h-10 mb-4 rounded-full">
              <svg style="fill:rgb(22,78,99)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M416 256s96-96 96-160c0-53-43-96-96-96s-96 43-96 96c0 29.4 20.2 65.5 42.1 96H320c-53 0-96 43-96 96s43 96 96 96h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H188.6c-6.2 9.6-12.6 18.8-19 27.2c-10.7 14.2-21.3 26.9-30 36.8H416c53 0 96-43 96-96s-43-96-96-96H320c-17.7 0-32-14.3-32-32s14.3-32 32-32h96zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32zM149.9 448c21.9-30.5 42.1-66.6 42.1-96c0-53-43-96-96-96s-96 43-96 96c0 64 96 160 96 160s3.5-3.5 9.2-9.6c.4-.4 .7-.8 1.1-1.2c3.3-3.5 7.1-7.8 11.4-12.8c.2-.2 .4-.4 .6-.6c9.4-10.8 20.7-24.6 31.6-39.8zM96 384c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z"/></svg>
            </div>
            <h6 class="mb-2 font-bold  text-cyan-900 leading-5 uppercase text-center">TU HOGAR LEJOS DE CASA</h6>
            <p class="mb-3 text-base text-gray-900 text-center">
             Te ayudamos a encontrar la mejor alternativa dentro de nuestras opciones de alojamiento, que te ofrecen la libertad de elección y calidad que mereces 
            </p>
          </div>
        </div>

        <!--BOX 4-->
        <div class="flex flex-col justify-between p-5 border rounded shadow-sm  hover:bg-gray-200 bg-gray-100">
          <div>
            <div class="flex md:ml-28 ml-32 items-center justify-center w-8 h-10 mb-4 rounded-full">
              <svg style="fill:rgb(22,78,99)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M320 32c-8.1 0-16.1 1.4-23.7 4.1L15.8 137.4C6.3 140.9 0 149.9 0 160s6.3 19.1 15.8 22.6l57.9 20.9C57.3 229.3 48 259.8 48 291.9v28.1c0 28.4-10.8 57.7-22.3 80.8c-6.5 13-13.9 25.8-22.5 37.6C0 442.7-.9 448.3 .9 453.4s6 8.9 11.2 10.2l64 16c4.2 1.1 8.7 .3 12.4-2s6.3-6.1 7.1-10.4c8.6-42.8 4.3-81.2-2.1-108.7C90.3 344.3 86 329.8 80 316.5V291.9c0-30.2 10.2-58.7 27.9-81.5c12.9-15.5 29.6-28 49.2-35.7l157-61.7c8.2-3.2 17.5 .8 20.7 9s-.8 17.5-9 20.7l-157 61.7c-12.4 4.9-23.3 12.4-32.2 21.6l159.6 57.6c7.6 2.7 15.6 4.1 23.7 4.1s16.1-1.4 23.7-4.1L624.2 182.6c9.5-3.4 15.8-12.5 15.8-22.6s-6.3-19.1-15.8-22.6L343.7 36.1C336.1 33.4 328.1 32 320 32zM128 408c0 35.3 86 72 192 72s192-36.7 192-72L496.7 262.6 354.5 314c-11.1 4-22.8 6-34.5 6s-23.5-2-34.5-6L143.3 262.6 128 408z"/></svg>
              
            </div>
            <h6 class="mb-2 font-bold  text-cyan-900 leading-5 uppercase text-center">LA EXPERIENCIA DE INMERSION</h6>
            <p class="mb-3 text-base text-gray-900 text-center">
             Con nuestro acompañamiento y el de los docentes de la escuela, el sendero de la inmersión al idioma y la cultura local va a ser todo lo que imaginas. 
            </p>
          </div>
        </div>


      </div>
</div>

</template>
<script>
export default {
  name: 'FeaturesComponent',
  props: {
     name:String,
        
  }  
}
</script>
