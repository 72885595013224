<template>
  <div id="programs card component w-auto">
    <div class="shadow-xl bg-gradient-to-t from-gray-900 to-cyan-700 rounded-t-lg md:hover:scale-110 w-auto">
      <span class="absolute ml-56 md:ml-60 mt-3 px-4 pt-5 pb-5 text-center uppercase rounded-full font-bold  bg-blue-600 text-white" v-if="program.new">New</span>
      <img class="rounded-t-lg" :src="program.get_picture" alt="">
  
      <div class="p-6">
          <h5 class="text-gray-200 text-xl uppercase text-center font-medium mb-2">{{program.name}}</h5>
          <p class= "text-gray-200 text-base text-center mb-4">{{program.short_description}}</p>
          <div class="flex items-center justify-center">
            <button type="button" class=" inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">más información</button>
          </div>
      </div>
    </div>
  </div>
</template>

<script>



export default {
  name: 'ProgramsCardComponent',
  props: {
     program: Object 
  },
}
</script>
