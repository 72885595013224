<template>
  <div>
     <BannerComponent name='Destinos' :image= "require('../assets/img/street2.jpg')" />
      <h1 class=" text-black text-4xl text-center mt-10 mb-5">Nuestros Destinos</h1>
      <p class="font-semibold text-cyan-700 text-xl mx-16 lg:mx-48  text-center leading-loose">Cada lugar tiene su encanto, cultura e historia en cada rincón, museos para todos los gustos, inmensos parques, castillos y
        palacios. Las posibilidades son infinitas para que tu aprendizaje no sea sólo en el aula.</p>
      <div class="flex justify-center">
         <div class="grid md:grid-cols-3 gap-x-11 gap-y-10 h-auto w-4/5 md:auto aspect-video mt-10 mb-40 ">
          <div v-for="destination, key in destinations" :key="key">
            <router-link v-bind:to="destination.get_absolute_url">
              <DestinationCardComponent :destination="destination" />
            </router-link>
          </div>
         </div>
      </div> 
  
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import { baseURL } from '../config.js'
import BannerComponent from '@/components/BannerComponent.vue'
import DestinationCardComponent from '@/components/DestinationCardComponent.vue'


export default {
  name: 'DestinationView',
  components: {
    BannerComponent,
    DestinationCardComponent
  },
  data() {
    return {
     destinations: [],
    };
  },
   mounted(){
    this.getDestinations();
  },
  methods:{
    getDestinations: async function(){
      await axios.get(baseURL + 'destination/')
                  .then((response) => {
                    this.destinations = response.data
                  })
                  .catch( error => {
                    console.log(error)
                  })
    }
  }

}
</script>