<template>
    <div>
        <BannerComponent name="" :image= "require('../assets/img/street2.jpg')" />
          
          <div v-if="notFound" class="flex mt-20 mb-20 justify-center">
              <h1>Lo sentimos, no encontramos nada de eso... </h1>
          </div>

          <div v-if="programs.length > 0">        
            <div class="flex justify-center">
              <div class="grid md:grid-cols-3 gap-x-6 gap-y-10 h-auto w-4/5 aspect-video mt-10 mb-40 ">
                <div v-for="program, key in programs" :key="key">
                  <router-link v-bind:to="program.get_absolute_url">
                    <ProgramsCardComponent :program="program" />
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div v-if="destinations.length > 0">
            <div class="flex justify-center">
              <div class="grid md:grid-cols-3 gap-x-11 gap-y-10 h-auto w-4/5 md:auto aspect-video mt-10 mb-40 ">
                <div v-for="destination, key in destinations" :key="key">
                  <router-link v-bind:to="destination.get_absolute_url">
                    <DestinationCardComponent :destination="destination" />
                  </router-link>
                </div>
              </div>
            </div>
          </div>
         

          

    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import { baseURL } from '../config.js'
import BannerComponent from '@/components/BannerComponent.vue'
import ProgramsCardComponent from '@/components/ProgramsCardComponent.vue'
import DestinationCardComponent from '@/components/DestinationCardComponent.vue'

export default {
  name: 'SearchView',
  components: {
    BannerComponent,
    ProgramsCardComponent,
    DestinationCardComponent,
  },
  data() {
    return {
     programs: [],
     destinations: [],
     notFound: false
    };
  },
  mounted(){
    this.getSearch()
  },
  watch: {
    '$route' (from) {
      if(from.name == 'resultados')
        this.getSearch()
    }
  },
  methods:{
    getSearch: async function(){
      this.programs = []
      this.destinations = []
      this.notFound = false
      await axios.get(baseURL + 'search/' + this.$route.params.search + '/')
                  .then((response) => {
                    if(response.data.programs)
                      this.programs = response.data.programs
                    else if(response.data.destinations)
                      this.destinations = response.data.destinations
                    else
                      this.notFound = true

                  })
                  .catch( error => {
                    console.log(error)
                  })
    }
  }
}
  
</script>