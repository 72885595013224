<template>
    <div id="home banner" class= "relative overflow-hidden lg:h-auto h-fit-content bg-no-repeat bg-cover mt-1" style="
    background-position: 100%;">
    <img class=" w-full lg:h-full h-96 bg-origin-border bg-full" :src="image">
    <div class="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed"
      style="background-color: rgba(0, 0, 0, 0.75)">
      <div class="flex justify-center items-center h-full">
        <div class="text-center text-white px-6 md:px-12">
          <h1 class="text-5xl md:text-8xl font-playlist text-white font-bold mt-4 mb-6 lg:mt-10 lg:mb-10">{{title}}</h1>
          <h3 class="text-xl md:text-4xl text-white mt-0 mb-1 lg:mb-6">{{subtitle}}</h3>
          <router-link to="/programas"><button type="button"
            class="lg:mt-10 mt-6 ring-2 inline-block px-6 md:px-6 py-2.5 border-2 text-xs md:text-xl border-white text-white hover:bg-sky-800 leading-tight tracking-wide font-semibold uppercase rounded hover:bg-opacity-6 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
            data-mdb-ripple="true" data-mdb-ripple-color="light">
            ¡Cuentame Más!
          </button></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default { 
name: 'HomeBannerComponent',
  props: {
    title: String,
    subtitle: String,
    image:String
  },
  
}
</script>
