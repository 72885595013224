<template>
<section class="mb-20 text-gray-700">
 <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <div class="mt-10">
          
        </div>
        <h1 class="text-center text-4xl mb-5 text-black md:mx-auto">
         Nuestro Equipo
        </h1>
        <p class=" text-cyan-700 font-semibold  text-center md:text-lg">
        Eunoia Travel esta formado por un equipo de profesionales dedicados a la enseñanza del idioma a traves de las experiencias culturales ¡Nos presentamos!
        </p>
      </div>

  <div class="grid md:grid-cols-3 gap-6 lg:gap-12 text-center mx-20">
    <div v-for="(member, key) in members"
        :key="key"
        
      >
      <div class="mb-12 md:mb-0">
        <div class="flex justify-center mb-6">
          <img
            :src="getImgUrl(member.image)"
            class="rounded-full shadow-lg w-32"
          />
        </div>
        <h5 class="text-xl font-semibold mb-4">{{member.name}}</h5>
        <h6 class="font-semibold text-blue-600 mb-4">{{member.job}}</h6>
        <p class="mb-4">
          {{member.description}}
        </p>
      </div>
    </div>
  </div>
  
  
    
</section>
</template>

<script>

export default {
  name: 'TestimonialsComponent',
  props: {
     name:String,
     title:String,
       
  },
  data() {
    return {
      members: [
        {name:"Karina Garbarini", job:"Educational Consultant & Teacher", image: "karina.jpeg", description:"Desde mi primer viaje a Inglaterra como TEFL teacher y Group Leader en 2008, mantengo intacto el entusiasmo por compartir esta maravillosa experiencia de inmersión cultural con estudiantes y profesores de todo el mundo. Acompaño a los alumnos y a sus familias desde el comienzo, brindando asesoramiento para que cada experiencia sea acorde a lo que deseen."},
        {name:"Marcelo Martín", job:"ESL / ESOL Teacher", image: "marcelo.jpeg", description:"Asesor en viajes educativos, culturales y de inmersión lingüística. Con más de 25 años de experiencia docente, imparto clases presenciales y online desde mi academia en España. Pongo todo mi empeño y experiencia para asegurar excelente calidad educativa durante la inmersión, así también como completa transparencia en el proceso previo a viajar, ayudando a los estudiantes y sus familias en cada paso."},
        {name:"Cristian Fazio", job:"Sales & Admin", image: "cristian.jpeg", description:"Coordino el área administrativa agilizando las tareas diarias y acompañando a los estudiantes y sus familias en el proceso de inscripción. Disfruto ser parte activa de la creación de los programas y procesos para lograr el éxito de cada experiencia."},
      ]
    };
  },
  methods: {
      getImgUrl(pic) {
          return require('../assets/img/'+pic)
      }
  }
  
  
  
}

</script>