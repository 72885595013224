<template>
    <div id="category component">
      <div class="col-span-1 brightness-75 hover:brightness-100">
        <router-link v-bind:to="category.get_absolute_url">
            <img :src="category.get_picture" class="w-full h-full aspect-video brightness-75" />
          <p class="text-center transparent rounded-b-lg pt-2 mb-2 uppercase -translate-y-10 bg-gradient-to-r from-gray-900 to-cyan-800 h-10 hover: text-white font-semibold  translate-x-0">{{ category.name }}</p>
        </router-link>
      </div>
    </div>
</template>


<script>

export default {
  
  name: 'CategoryCardsComponent',
  props: {
     category: Object   
  }
}

</script>