
<template>
    
      <div class="relative overflow-hidden lg:h-auto bg-no-repeat mt-1 " style="background-position: 100%">
        <img class=" w-full h-full lg:bg-bottom lg:bg-cover" :src="image">
        <div class="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed" style="background-color: rgba(0, 0, 0, 0.75)">
          <div class="flex justify-center items-center h-full">
            <div class="text-center text-white px-6 md:px-12">
              <h1 class="text-7xl lg:text-8xl font-playlist text-white font-bold mt-2 lg:mt-0 mb-6">{{name}}</h1>
            </div>
          </div>
        </div>
      </div>
    

</template>


<script>

export default {
  name: 'BannerComponent',
  props: {
     name:String,
     image:String
        
  }  
}

</script>