

<template>
<section class="overflow-hidden text-gray-700 ">
  <div class="container py-2 ">
      <div class="grid md:grid-cols-3 w-2/3 text-center justify-center md:place-content-center">
       <div v-for="picture, key in pictures" :key="key">
        <div class="w-full h-full p-1 md:p-2">
          <img alt="gallery" class="object-cover cursor-pointer block md:w-full h-full rounded-lg"
           :src="picture.get_picture" @click="showModal(picture.get_picture)">
        </div>
      </div>
      </div> 
    </div>
  
  <div id="modal" class="hidden z-40 fixed top-0 left-0 z-80 w-screen h-screen bg-black/70 flex justify-center items-center">
      <!-- The close button -->
      <a class="fixed top-6 right-8 text-white text-5xl font-bold" href="javascript:void(0)"
        @click="closeModal()">&times;</a>

      <!-- A big image will be displayed here -->
      <img id="modal-img" class="max-w-[800px] max-h-[600px] object-cover" />
  </div>
 
</section>
</template>
<style>
</style>

<script>



export default {
  name: 'SimpleGalleryComponent',
  props: {
     name: String,
     pictures: Array,
  },
  data(){
    return {
    };
  },
   mounted(){
  },
  methods:{
    showModal: async function(src){
      let modal = document.getElementById("modal");
      let modalImg = document.getElementById("modal-img");
      let body = document.getElementById("body");
        body.style.overflow = 'hidden';
        modal.classList.remove('hidden');
            modalImg.src = src;
    },
    closeModal: async function(){
      let modal = document.getElementById("modal");
      let body = document.getElementById("body");
       body.style.overflow = 'auto';
       modal.classList.add('hidden');
    }
  } 
}
</script>