<template>
  <div class="home">
     <HomeBannerComponent title='Estudia Inglés en el Reino Unido' :image = "require('../assets/img/tower_bridge_London.jpg')" subtitle='Perfecciona tu nivel mientras recorres lugares increíbles, vives otras culturas y conoces gente de todo el mundo'/>
      <div class="items-center justify-center bg-white mx-1 mb-0">
        <div class="grid md:mb-2 md:grid-cols-3 gap-x-1 w-full mt-1">
            <div v-for="(category, key) in categories" :key="key">
              <router-link v-bind:to="category.get_absolute_url">
                <CategoryCardsComponent :category= category />
              </router-link>
            </div>
        </div>
      </div>
    <section>
      <AboutComponent />
    </section>

    <section class="mt-10 mx-6 ">
      <div class="text-center md:max-w-xl lg:max-w-3xl mx-auto">
        <div class="inline-flex">
          <p class="inline-block ml-1 mr-2 px-3 mb-4 text-2xs font-semibold uppercase tracking-wider text-white rounded-full bg-cyan-700">
            #travel
          </p>
          <p class="inline-block mr-2 px-3 mb-4 text-2xs font-semibold uppercase tracking-wider text-white rounded-full bg-cyan-700">
            #discover
          </p>
        </div>
        <h1 class="text-center  text-black text-4xl mb-3">Descubre Nuestros Destinos</h1>
        <p class="text-center font-semibold text-cyan-700 lg:text-xl mb-10 md:mb-12 md:pb-0">
          Encuentra el destino perfecto para tu experiencia de inmersion. 
          Cada lugar tiene su encanto y juntos vamos a encontrar la escuela y el tipo de curso que mejor se adapte a tus necesidades.

        </p>
        
      </div>
      <div class=" items-center justify-center">
        <div class="grid md:grid-cols-3 gap-x-10 h-full w-full mt-2">
            <div v-for="(destination, key) in destinations.slice(0,3)" :key="key">
                <div class="col-span-1  ">
                  <router-link v-bind:to="destination.get_absolute_url">
                    <DestinationsComponent :destination= destination />
                  </router-link>
                </div>
            </div>   
        </div>
      </div>
    </section>

    <section>
      <FeaturesComponent />
    </section>

    <section class="mb-10 mt-5">
      <MiniBannerComponent />
    </section>

    <section class="bg-gray-100 pt-10 pb-10 mb-10 border">
      <TestimonialsComponent title="Testimonios"/>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src

import HomeBannerComponent from '@/components/HomeBannerComponent.vue'
import CategoryCardsComponent from '@/components/CategoryCardsComponent.vue'
import AboutComponent from '@/components/AboutComponent.vue'
import TestimonialsComponent from '@/components/TestimonialsComponent.vue'
import DestinationsComponent from '@/components/DestinationsComponent.vue'
import FeaturesComponent from '@/components/FeaturesComponent.vue'
import MiniBannerComponent from '@/components/MiniBannerComponent.vue'


import axios from 'axios'
import { baseURL } from '../config.js'

export default {
  name: 'HomeView',
  components: {
     HomeBannerComponent,
     CategoryCardsComponent,
     AboutComponent,
     DestinationsComponent,
     FeaturesComponent,
     MiniBannerComponent,
     TestimonialsComponent
  },
  data() {
    return {
      destinations: [],
      categories: [],
    };
  },
  mounted(){
    this.getDestinations(),
    this.getCategories()
  },
  methods:{
    getDestinations: async function(){
      await axios.get(baseURL + 'destination/')
                  .then((response) => {
                    this.destinations = response.data
                  })
                  .catch( error => {
                    console.log(error)
                  })
    },
    getCategories: async function(){
    await axios.get(baseURL + 'category/')
                .then((response) => {
                  this.categories = response.data
                })
                .catch( error => {
                  console.log(error)
                })
    }

  }
}
</script>
