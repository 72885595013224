<template> 
    <div id="Program Datail">
        <BannerComponent name= 'Programas' :image= "require('../assets/img/street2.jpg')" />
        <div class="grid md:grid-cols-3 items-center md:mt-0 mx-10 mt-5">
           <div class="grid col-span-2 content-center items-center">
              <h1 class="text-center text-5xl text-black md:mt-0 md:text-left mb-6 ">{{program.name}}</h1>
              <h1 class="text-center text-cyan-700 md:text-left mb-6 uppercase">¿En que consiste?</h1>
              <p class="mb-6  text-gray-700 leading-loose text-justify text-lg md:w-full">{{program.description}}</p>
              <h1 class="text-center text-black md:text-left mb-6 uppercase">Galeria</h1>
                  <div class="grid items-center w-full content-center ml-16 md:ml-3 mb-10 md:col-1">
                    <SimpleGalleryComponent :pictures = program.pictures />
                  </div>
           </div>
        
           
          <div class="grid bg-gray-200 border md:ml-10 md:w-auto pb-10  md:items-center col-span-1 mt-10 mb-10 justify-center">
            <h1 class="md:text-center w-full text-center mt-5 md:mx-2 text-gray-700 uppercase text-lg md:text-lg mb-2"> ¿Te interesa nuestro programa? </h1>
            <h1 class="md:text-center  md:ml-3 italic text-center text-gray-700 uppercase text-lg mb-6">¡Consultanos!</h1>
            <div class="content-center w-full mx-11 pr-20 md:px-10 md:mx-5 ">
              <FormComponent /> 
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { baseURL } from '../config.js'
import BannerComponent from '@/components/BannerComponent.vue'
import SimpleGalleryComponent from '@/components/SimpleGalleryComponent.vue'
import FormComponent from '@/components/FormComponent.vue'


export default {
  name: 'ProgramDetailView',
  props: {
    name: String,
  },
  components: {
    BannerComponent,
    SimpleGalleryComponent,
    FormComponent
  },
data() {
    return {
     program: [],
    };
  },
   created(){
    this.getProgram()
  },
  watch: {
    '$route' (to) {
      if(to.name == 'programa')
        this.getProgram()
    }
  },
  methods:{
    getProgram: async function(){
      await axios.get(baseURL + `program/${this.$route.params.program_slug}/`)
                  .then((response) => {
                    this.program = response.data
                  })
                  .catch( error => {
                    console.log(error)
                  })
    }
  }
}
</script>
